import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const SuccessPage = ({
  data: {
    site
  },
}) => {
  
  return (
    <Layout>
      <Helmet>
        <title>Success — {site.siteMetadata.title}</title>
      </Helmet>
      <div className="success">
        <div className="post-thumbnail" style={{backgroundImage: `url('/assets/alexander-andrews-HgUDpaGPTEA-unsplash.jpg')`, marginBottom: 0}}>
          <h1 className="post-title">dziękujęmy za zaufanie!</h1>
          <p>wkrótce ktoś się do Ciebie odezwie dograć szczegóły</p>
        </div>
      </div>
    </Layout>
  )
}
export default SuccessPage
export const pageQuery = graphql`
  query SuccessPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`